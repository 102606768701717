import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Scroller from "../Containers/Scroller";
import SmallCarousel from "../Containers/SmallCarousel";
import DataToUiFormater from "./Api/DataToUiFormater";
// import ProductsController from "./Api/ProductsController";

const Product = () => {
    const [product, setProduct] = useState("");
    const productId = useParams().productId;
    React.useEffect(() => {
        let data = JSON.parse(localStorage.getItem("NiceHouse" || "OneproductApi"));
        // console.log(data);
        DataToUiFormater.FormateHouse(data);
        setProduct(data);
        // return;
        // const Done = ({ data }) => {
        //     // setProduct(data);
        //     localStorage.setItem("OneproductApi", JSON.stringify(data));
        //     console.log(data);
        // };
        // let _product = ProductsController.GetHouse(Done, productId);
        // _product && setProduct(_product);
    }, [productId]);

    return (
        <Scroller className="">
            {product && (
                <>
                    <SmallCarousel Images={product.imageUrls} />
                    <p className="font-bold carbonic text-lg py-3 text-center ">
                        <span>{product.ownerName}</span>
                        <br />
                        <span className="text-sm pl-2 carbonic-1">للتواصل :</span>
                        <span>{product.contact}</span>
                    </p>

                    <p className="font-bold carbonic text-xl py-3">
                        <span>{product.title}</span>
                        <br />
                        <span className="text-2xl">{product.price}</span>
                        <br />
                    </p>
                    {product.props?.map((prop, i) => (
                        <p className="font-bold carbonic-2" key={i}>
                            <span className="orange-text text-2xl ">. </span>
                            &#160; &#160;
                            <span className="min-w-[190px]">{prop.key}</span>
                            &#160; &#160;
                            <span className="text-lg carbonic ">{prop.value}</span>
                        </p>
                    ))}
                </>
            )}
        </Scroller>
    );
};

export default Product;
