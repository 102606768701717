import React from "react";
import BackGroundSvg from "../Containers/BackGroundSvg";
let _i = 11;
const PopupContainer = ({ close, children, className }) => {
    const [, setI] = React.useState(_i);
    const [blur, setBlur] = React.useState("");

    const _ChangeBg = (value) => {
        if (value === 0) {
            _i = -1;
        } else {
            _i += value;
            _i = _i > 13 ? 0 : _i;
            _i = _i < 0 ? 13 : _i;
        }
        setI(_i);
    };
    const changeBlur = () => {
        if (blur === "") setBlur("backdrop-blur-xs");
        else if (blur === "backdrop-blur-xs") setBlur("backdrop-blur-sm");
        else if (blur === "backdrop-blur-sm") setBlur("backdrop-blur-md");
        else if (blur === "backdrop-blur-md") setBlur("backdrop-blur-lg");
        else if (blur === "backdrop-blur-lg") setBlur("");
    };
    return (
        <>
            <div id="13" onClick={({ target }) => target.id === "13" && close()} className={`fixed row z-20  ${blur} inset-0 ${className}`}>
                <div id="13" className="h-screen max-w-2xl m-auto flex-grow z-20">
                    {children}
                </div>
                {/* {newFunction(_ChangeBg, changeBlur)} */}
            </div>
            {/* <BackGroundSvg iOut={_i} /> */}
        </>
    );
};

export default PopupContainer;
function newFunction(_ChangeBg, changeBlur) {
    return (
        <div className="flex flex-row absolute z-20">
            <p onClick={() => _ChangeBg(1)} className="bg-white cursor-pointer text-3xl w-9 ml-1 rounded-full text-center ">
                {"<"}
            </p>
            <p onClick={() => _ChangeBg(-1)} className="bg-white cursor-pointer text-3xl w-9 rounded-full text-center ">
                {">"}
            </p>
            <p onClick={changeBlur} className="bg-white cursor-pointer text-xl px-2 mx-1 pt-1 rounded-full text-center ">
                {"Blur"}
            </p>
            <p onClick={() => _ChangeBg(0)} className="bg-white cursor-pointer text-3xl w-9 rounded-full text-center ">
                {"x"}
            </p>
        </div>
    );
}
