import React from "react";
import { Routes, Route } from "react-router-dom";
import ObjectCreator from "../FilesComponents/ObjectCreator";
import AddProduct from "../Products/AddProduct";
// import AddProductOld from "../Products/AddProductOld";
import Product from "../Products/Product";
import Products from "../Products/Products";

const AuthRoutes = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Products />} />
                <Route path="/object-creator" element={<ObjectCreator />} />
                {/* <Route path="/products/add" element={<AddProductOld />} /> */}
                <Route path="/products/add" element={<AddProduct />} />
                <Route path="/products/:productId" element={<Product />} />
                {/* <Route path="/" element={<DashBoard />} /> */}
                {/* <Route path="/products" element={<Products />} /> */}
            </Routes>
            {/* <BottomBar /> */}
        </>
    );
};

export default AuthRoutes;
