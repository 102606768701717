import React from "react";
import Languages from "../Utils/Languages";

const String = ({ prop }) => {
    const [inputValue, setInputValue] = React.useState(prop.value || "");
    const { title, required } = prop;
    return (
        <input
            className="popup-card outline-none"
            name={title}
            type="text"
            value={inputValue}
            required={required}
            // autoFocus={autoFocus}
            placeholder={Languages[title] || title}
            onChange={({ target }) => {
                prop.value = target.value;
                setInputValue(target.value);
            }}
        />
    );
};

export default String;
