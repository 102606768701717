import React, { useState } from "react";
import Button from "../Components/Button";
import NumberInput from "../Components/NumberInput";
const AreaCard = ({ setPopupClass, prop, setOtherData, otherData, TypeTitle, multible }) => {
    const [width, setWidth] = useState("");
    const [length, setLength] = useState("");
    const widthRef = React.useRef();

    const generateUnActiveMessage = () => {
        let message = "يجب ادخال ";
        if (width === "") {
            message += length === "" ? " العرض والطول " : " العرض ";
        } else if (length === "") message += " الطول ";
        return message;
    };
    const infoMessageOnUnActiveClick = (setBtnTitle) => {
        let disabledMessage = generateUnActiveMessage();
        setBtnTitle(disabledMessage);
    };

    const close = () => setPopupClass("");

    const addType = () => {
        let _newData = otherData.concat({ type: prop.key, width, length, title: TypeTitle, id: `${prop.key}-${otherData.length}` });
        setOtherData(_newData);
        prop.values = _newData;

        if (!multible) close();
        else {
            setLength("");
            setWidth("");
        }
        // widthRef.current.focus();
    };
    return (
        <div className="popup-card mb-3">
            <p onClick={close} className="text-sm orange-text top-3">
                إغلاق
            </p>
            <p className=" carbonic text-lg flex-grow pb-6 text-center">{prop.title}</p>
            <div className="row mb-3 justify-around">
                <Button
                    onClick={addType}
                    onUnActiveClick={infoMessageOnUnActiveClick}
                    title={`اضافة`}
                    color="redText"
                    active={width && length}
                    extraClass="max-w-[30%]"
                />
                <NumberInput Key="Width" type="number" setValue={setWidth} value={width} autoFocus inputRef={widthRef} className="max-w-[30%]" />
                <NumberInput Key="Length" type="number" setValue={setLength} value={length} className="max-w-[30%]" />
            </div>
        </div>
    );
};

export default AreaCard;
