import React from "react";
import ListLabel from "./ListLabel";

const Options = ({ prop, onDone }) => {
    const onSelect = (item) => {
        prop.value = prop.value === item.value ? 0 : item.value;
        onDone(Math.random());
    };
    // let flexClass = prop?.items?.length > 3 ? "col" : "row-center";
    const containerClass = "popup-card" || "py-2 m-2 rounded-xl carbonic-card shadow-sm"; // + flexClass;
    return (
        <div className={containerClass}>
            <p className="font-bold px-3 orange-text-2">{prop.title}</p>
            <div className="scroller horizontal-scroller">
                {prop.items?.map((item) => (
                    <ListLabel key={item.key} title={item.title} active={prop.value === item.value} onClick={() => onSelect(item)} />
                ))}
            </div>
        </div>
    );
};

export default Options;
