import React from "react";
import Languages from "../Utils/Languages";
const ButtonColors = {
    red: "red-card white-text",
    redText: "white-card red-text",
    green: "green-card white-text",
    orange: "orange-card white-text",
    cyan: "cyan-card white-text",
    undefined: "carbonic carbonic-card",
};
const Button = ({ title, onClick, onUnActiveClick, color, extraClass, active = true }) => {
    const [unactiveMessage, setUnActiveMessage] = React.useState("");
    let buttonColors = active ? ButtonColors[color] : ButtonColors[color] + " opacity-60 ";
    const className = extraClass + " tap-highlight font-bold cursor-pointer shadow-lg px-2 py-1 rounded-xl text-center " + buttonColors;
    const unActiveAndClickd = (disabledMessage) => {
        setUnActiveMessage(disabledMessage);
        setTimeout(() => {
            setUnActiveMessage("");
        }, 1000);
    };
    const _onClick = active ? onClick : () => onUnActiveClick(unActiveAndClickd);

    return (
        <>
            {unactiveMessage && (
                <p className="font-bold carbonic text-center blur-card z-10 absolute backdrop-blur p-5 left-0 -mt-3 right-0 min-w-max">{unactiveMessage}</p>
            )}
            <p onClick={_onClick} className={className}>
                {Languages[title] || title}
            </p>
        </>
    );
};

export default Button;
