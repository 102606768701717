import React, { useState } from "react";
import FormTextArea from "../Components/FormTextArea";
import TextInput from "../Components/TextInput";
import Scroller from "../Containers/Scroller";
import Languages from "../Utils/Languages";

const ObjectCreator = () => {
    const [total, setTotal] = useState("");
    const [procesedString, setProcesedString] = useState("");
    const [cityId, setCityId] = useState("");
    const [popupMessage, setPopupMessage] = useState("");

    const Space12 = "        ";
    const fetchSalesPerDay = () => {
        let city = "new Neighborhood";
        let di = "";
        total.split("\n")?.forEach((item, i) => {
            let id = i + 1;
            let newVal = `
                ${Space12}{ 
                   ${Space12} ${id} ,
                   ${Space12} ${city}
                   ${Space12} {
                       ${Space12} Id=${id} ,
                       ${Space12} CityId=${cityId},
                       ${Space12} CountryCode="IQ" ,
                       ${Space12} ArabicName="${item}" ,
                       ${Space12} EnglishName=""
                    ${Space12}}
                ${Space12}},`;
            di += newVal;
        });
        setProcesedString(di);
    };
    const copy = () => {
        navigator.clipboard.writeText(procesedString);
        setPopupMessage("تم النسخ");
        setTimeout(() => {
            setPopupMessage("");
        }, 1000);
    };

    return (
        <div className="flex flex-col cursor-pointer row">
            <div className="w-56 pt-[30px] ">
                <TextInput value={cityId} setValue={setCityId} Key="CityId" />
                <p className="font-bold cyan-card text-white text-center shadow-xl p-3 bg-white rounded-xl my-3" onClick={fetchSalesPerDay}>
                    {Languages.Createtion}
                </p>

                <FormTextArea rows={25} value={total} setValue={setTotal} placeholder="Cities" />
            </div>
            <Scroller className="flex-grow">
                <p style={{ direction: "ltr" }} onClick={copy} className="font-light shadow-xl text-left p-3 bg-white rounded-xl m-2">
                    {procesedString && procesedString.split(",").map((item) => <p>{item},</p>)}
                </p>
            </Scroller>
            {popupMessage && (
                <div className="fixed row inset-0 justify-center items-center">
                    <p className="font-bold cyan-card text-center text-white px-10 py-3 rounded-xl shadow-xl">{popupMessage}</p>
                </div>
            )}
        </div>
    );
};

export default ObjectCreator;
