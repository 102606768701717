import React, { useState } from "react";
import NextButton from "../Components/NextButton";
import PrevButton from "../Components/PrevButton";

const SmallCarousel = ({ Images, height = 460 }) => {
    const [selectedImage, setSelectedImage] = useState(0);
    const [fadeIn, setFadeIn] = useState(true);
    const [translateDirction, setTranslateDirction] = useState("translateRight");

    const nextBtnClick = () => {
        let newIndex = selectedImage + 1;
        setSelectedImage(newIndex < Images.length ? newIndex : 0);
        setFadeIn(!fadeIn);
        setTranslateDirction("translateLeft");
    };

    const prevBtnClick = () => {
        let newIndex = selectedImage - 1;
        setSelectedImage(newIndex > -1 ? newIndex : Images.length - 1);
        setFadeIn(!fadeIn);
        setTranslateDirction("translateRight");
    };

    return Images.length > 0 ? (
        <div className="flex relative my-2" style={{ height }}>
            <img
                key={selectedImage}
                className={translateDirction}
                style={{
                    height,
                    margin: "auto",
                    objectFit: "cover",
                }}
                src={Images[selectedImage]}
                alt="hardwareImages"
            />
            {Images.length > 1 && (
                <>
                    <NextButton right={10} onClick={nextBtnClick} />
                    <PrevButton left={10} onClick={prevBtnClick} />
                </>
            )}
        </div>
    ) : (
        <></>
    );
};

export default SmallCarousel;
