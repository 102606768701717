const HouseProps = {
    PropsOptions: {
        Material: ["Bricks", "Blocks"],
        Property: ["Transcend", "AgriculturalOwnership", "GovernmentOwnership"],
        Foundation: ["Brikes", "Concrete"],
        OfferType: ["Sale", "Rent", "Mortgage"],
        Quality: ["Bad", "Middle", "High"],
        Distance: ["Near", "Middle", "Far"],
        Boolean: ["Yes", "No"],
        Currency: ["IQD", "USD"],
        HouseQuality: ["Empty", "New", "Middle", "Old"],
    },
    Groups: [
        {
            title: "Maininfo",
            progres: 5,
            props: [
                //
                { key: "title", type: "String", required: true },
                { key: "contact", type: "Number", required: true },
                { key: "addressId", type: "String", required: true },
                { key: "price", type: "Currency", optionsKey: "currency", required: true },
                // { key: "currency", type: "Currency", },
                { key: "area", type: "Area" },
            ],
        },
        {
            title: "HouseInfo",
            progres: 9,
            props: [
                //
                { key: "offerType", type: "OfferType", required: true },
                { key: "property", type: "Property", required: true },
                { key: "material", type: "Material", required: true },
                { key: "foundation", type: "Foundation", required: true },
            ],
        },
        {
            title: "Area",
            progres: 12,
            props: [
                //
                // { key: "interfaceLength", type: "", },
                { key: "floorCount", type: "Number" },
                { key: "streetWidth", type: "Number" },
            ],
        },
        {
            title: "Quality",
            progres: 18,
            props: [
                //
                { key: "houseQuality", type: "HouseQuality" },
                { key: "neighborsQuality", type: "Quality" },
                { key: "internetServiceQuality", type: "Quality" },
                { key: "electricalServiceQuality", type: "Quality" },
                { key: "waterServiceQuality", type: "Quality" },
                { key: "sewerServiceQuality", type: "Quality" },
            ],
        },
        {
            title: "Distance",
            progres: 21,
            props: [
                //
                { key: "distanceToSchool", type: "Distance" },
                { key: "distanceToMarket", type: "Distance" },
                { key: "distanceToTheHealthCenter", type: "Distance" },
            ],
        },
        {
            title: "Boolean",
            progres: 25,
            props: [
                //
                { key: "isItStructure", type: "Boolean" },
                { key: "doseHaveGarden", type: "Boolean" },
                { key: "doesItContainCeramic", type: "Boolean" },
                { key: "isTheFlooCeramic", type: "Boolean" },
            ],
        },
        {
            title: "HouseDetails",
            progres: 30,
            parntKey: "houseDetails",
            props: [
                //
                { key: "room", type: "MultipleArea" },
                { key: "wc", type: "MultipleArea" },
                { key: "livingRoom", type: "MultipleArea" },
                { key: "guestRoom", type: "MultipleArea" },
                { key: "kitchen", type: "MultipleArea" },
                { key: "garden", type: "MultipleArea" },
            ],
        },
    ],
    GroupsTitles: {
        Maininfo: "المعلومات الاساسية",
        HouseInfo: "معلومات المنزل",
        Area: "المساحة",
        Quality: "الجودة",
        Distance: "المسافات",
        Boolean: "معلومات بشأن المنزل",
        HouseDetails: "خريطة المنزل",
    },
    PropsNames: {
        title: "الاسم",
        price: "السعر",
        addressId: "العنوان",
        contact: "رقم الهاتف",
        currency: "العملة",
        offerType: "نوع العرض",
        material: "البناء",
        age: "العمر",
        houseQuality: "جودة المنزل",
        streetWidth: "عرض الشارع",
        property: "نوع الطابو",
        waterServiceQuality: "جودة خدمة المياه",
        electricalServiceQuality: "جودة خدمة الكهرباء",
        sewerServiceQuality: "جودة خدمة الصرف الصحي",
        internetServiceQuality: "جودة  خدمة الانترنت",
        doseHaveGarden: " يحتوي على حديقة",
        area: "المساحة",
        doesItContainCeramic: " يحتوي على سيراميك",
        isTheFlooCeramic: " الارض كاشي",
        isItStructure: " يحتوي على بناء",
        interfaceLength: "الواجهة",
        sideLength: "العرض",
        floorCount: "عدد الطوابق",
        foundation: "الأساس",
        neighborsQuality: "جودة الجيران",
        distanceToSchool: "المسافة إلى المدرسة",
        distanceToMarket: "المسافة إلى السوق",
        distanceToTheHealthCenter: "المسافة إلى المركز الصحي",
        distances: {
            distanceToSchool: "المدرسة",
            distanceToMarket: "السوق",
            distanceToTheHealthCenter: "المركز الصحي",
        },
        Qualities: {
            waterServiceQuality: "المياه",
            electricalServiceQuality: "الكهرباء",
            sewerServiceQuality: "الصرف الصحي",
            internetServiceQuality: "الانترنت",
            neighborsQuality: "الجيران",
        },
        room: "الغرف",
        wc: "الحمامات",
        livingRoom: "الصالات",
        guestRoom: "غرف الضيوف",
        kitchen: "المطابخ",
        garden: "الحدائق",
        houseDetails: "تفاصيل المنزل",
    },
};
export default HouseProps;
