const Bird = (info, time = 1300) => {
    BirdMethods.setBird({ info, class: "bird-in" });
    setTimeout(() => {
        BirdMethods.setBird({ info, class: "bird-out" });
        setTimeout(() => {
            BirdMethods.setBird(null);
        }, 200);
    }, time);
};

export const BirdMethods = {
    setBird: () => {},
};

export default Bird;
