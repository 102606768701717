import React from "react";
import { BirdMethods } from "./Bird";

const BirdContainer = ({ close, children, className }) => {
    const [bird, setBird] = React.useState(null);

    React.useEffect(() => {
        BirdMethods.setBird = setBird;
    }, []);

    return bird ? (
        <div className={`bird max-w-2xl mx-auto font-bold carbonic z-50`}>
            <p className={`rounded-l p-5 shadow-lg carbonic-card max-w-[330px] ${bird.class}`}>{bird.info}</p>
        </div>
    ) : (
        <></>
    );
};

export default BirdContainer;
