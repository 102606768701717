import React from "react";

const IconButton = ({ onClick, icon = "+", className = "green-text" }) => {
    const _class = className + " cursor-pointer carbonic-card-1 shadow-lg text-3xl min-w-[40px] min-h-[40px] justify-center rounded-full text-center";
    return (
        <p onClick={onClick} className={_class}>
            {icon}
        </p>
    );
};

export default IconButton;
