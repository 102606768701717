import React from "react";
import { Link } from "react-router-dom";
import { Pin } from "../../Svgs/Svg";

const ProductsCard = ({ product, updateStorge }) => {
    const onPin = () => {
        product.isPined = !product.isPined;
        updateStorge(product);
    };
    return (
        <div className="shadow-lg row carbonic-card rounded-3xl p-5 hover:-translate-y-1 transition-all mt-5">
            <img alt="2" className="w-[40%] object-cover rounded-xl " src={product.imageUrl} />
            <div className="px-5">
                <p className="font-bold carbonic text-xl pb-2">
                    <span>{product.title}</span>
                    <br />
                    <span className="font-bold carbonic py-3 text-2xl">{product.price}</span>
                </p>
                <p className="font-bold  carbonic-2 space-x-4">
                    <span>{product.description}</span>
                </p>
                <Link to={`/products/${product.id}`} style={{ textDecorationLine: "unset" }}>
                    <span className="font-bold cyan-text space-x-4"> المزيد ...</span>
                </Link>
            </div>
            <Pin className={product.isPined ? "cyan-fill" : "tint"} onClick={onPin} />
        </div>
    );
};

export default ProductsCard;
