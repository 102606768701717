import React, { useState } from "react";
import Button from "../Components/Button";
import StringFormater from "../Utils/StringFormater";
import AreaCard from "./AreaCard";
import AreaLabels from "./AreaLabels";

const MultipleArea = ({ prop, onDone }) => {
    const storageOpject = JSON.parse(localStorage.getItem("colctedHouseInfo")) || { houseDetails: [] };
    const _OtherData = storageOpject.houseDetails?.filter((o) => o.type === prop.key) || [];
    const [popupClass, setPopupClass] = useState("");
    let [otherData, setOtherData] = useState(_OtherData); //prop.values?.filter((o) => o.type === prop.key) || []);

    const TypeTitle = StringFormater.NumberAsString(prop.key, otherData.length);

    const removeType = (i) => {
        otherData = otherData.filter((_, _i) => _i !== i);
        otherData.forEach((item, i) => {
            item.title = StringFormater.NumberAsString(item.type, i);
            item.id = `${item.type}-${i}`;
        });
        setOtherData(otherData);
        prop.values = otherData;
    };

    return (
        <div className={`col ${popupClass}`}>
            {popupClass ? (
                <div className="self-center max-w-[100%]">
                    <AreaCard multible TypeTitle={TypeTitle} setPopupClass={setPopupClass} setOtherData={setOtherData} otherData={otherData} prop={prop} />
                    {otherData.length > 0 && <AreaLabels otherData={otherData} removeType={removeType} />}
                </div>
            ) : (
                <div className="row-center popup-card">
                    <span
                        className={`text-sm text-white rounded-full py-0.5 w-6 text-center ${
                            otherData.length > 0 ? " cyan-card shadow-cyan " : " carbonic-card shadow-lg carbonic"
                        }`}>
                        {otherData.length}
                    </span>
                    <p className="carbonic ml-auto mr-3">
                        <span>{prop.title}</span>
                    </p>
                    <Button onClick={() => setPopupClass("popup-2 blur-card-1")} title={`+ اضافة`} color="redText" extraClass="px-4 text-sm" />
                </div>
            )}
        </div>
    );
};
export default MultipleArea;
