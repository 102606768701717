import React from "react";
import ProgresBar from "../Components/ProgresBar";
import SubmitButton from "../Components/SubmitButton";
import Languages from "../Utils/Languages";

const Form = ({ children, onSubmit, submitText = "Confirm", className, cancle, canSubmit = true, cancleText = "Cancel", progres }) => {
    const _onSubmit = (e) => {
        e.preventDefault();
        canSubmit && onSubmit(e);
        return false;
    };
    const _className = `backdrop-blur-xs col rounded-xl relative mx-auto  overflow-x-hidden max-w-5xl  ${className}` + (canSubmit ? "" : " disabled");

    return (
        <form className={_className} onSubmit={_onSubmit}>
            <div className="scroller popup">{children}</div>
            {cancle ? (
                <div className="absolute bottom-0 left-2 right-2 backdrop-blur">
                    <div className="row justify-around ">
                        <SubmitButton text={Languages[submitText]} />
                        <input
                            type="button"
                            onClick={cancle}
                            value={Languages[cancleText]}
                            className="font-bold cursor-pointer shadow-lg carbonic-card-1 carbonic rounded-xl px-5 py-1.5 my-2"
                        />
                    </div>
                    {progres && <ProgresBar progres={progres} />}
                </div>
            ) : (
                <SubmitButton text={Languages[submitText]} />
            )}
        </form>
    );
};

export default Form;
