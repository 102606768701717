const Languages = {
    addressId: "العنوان",

    Quality: "الجودة",
    Distance: "المسافة",

    Bricks: "طابوق",
    Blocks: "بلوك",
    Transcend: "عقد زراعي",
    AgriculturalOwnership: "طابو زراعي",
    GovernmentOwnership: "طابو صرف",
    Concrete: "كونكريت",
    Brikes: "صب",

    Bad: "سيئة",
    // Normal: "طبيعية",
    High: "جيدة",
    Middle: "متوسطة",
    VeryHigh: "جيد جدا",

    Empty: "غير مسكون",
    New: "جديد",
    Old: "قديم",

    None: "غير محدد",
    Near: "قريب",
    Far: "بعيدة",
    USD: "دولار",
    IQD: "دينار",

    Sale: "بيع",
    Rent: "إيجار",
    Mortgage: "رهن",

    Send: "إرسال",
    Next: "التالي",
    Previous: "السابق",
    Save: "حفظ",
    Confirm: "تأكيد",
    Cancel: "إلغاء",
    Delete: "حذف",
    Edit: "تعديل",
    Add: "إضافة",
    Back: "رجوع",
    Search: "بحث",
    Pass: "تخطي",
    Yes: "نعم",
    No: "لا",

    Clear: "حذف",
    AddNew: "إضافة جديد",
    Create: "إنشاء",

    TheHouse: "البيت",
    House: "بيت",
    HouseTitles: {
        Age: "كم هو عمر البناء ؟",
        SideLength: "كم هو عرض المنزل ؟",
        InterfaceLength: "كم هي واجهة المنزل ؟",
        WCsCount: "كم هو عدد الصحيات ؟",
        FloorCount: "كم هو عدد الطوابق ؟",
        Area: "كم هي مساحة المنزل ؟",
        StreetWidth: "كم هو عرض الشارع ؟",
        DistanceToTheHealthCenter: "كم هي المسافة إلى المركز الصحي ؟",
        DistanceToMarket: "كم هي المسافة إلى السوق ؟",
        HouseDetials: "خريطة المنزل ",
    },

    AddImages: "إضافة صور",

    CityId: "CityId",
    Createtion: "توليد",
    Cities: "المدن",
    //House Map : "خريطة المنزل",
    Width: "العرض",
    Length: "الطول",
    Room: "الغرفة",
    Wc: "التواليت",
    LivingRoom: "الصالة",
    GuestRoom: "غرفة الاستقبال ",
    Kitchen: "المطبخ ",
    Types: {
        Room: "الغرف",
        Wc: "الصحيات",
        LivingRoom: "الصالات",
        GuestRoom: "غرف الاستقبال",
        Kitchen: "المطابخ",
    },

    room: "الغرفة",
    wc: "الحمام",
    livingRoom: "الصالة",
    guestRoom: "الاستقبال",
    kitchen: "المطبخ",
};

export default Languages;
