import React, { useState } from "react";
import { Link } from "react-router-dom";
import IconButton from "../Components/IconButton";
import Scroller from "../Containers/Scroller";
import ProductsController from "./Api/ProductsController";
import ProductsCard from "./Components/ProductsCard";

const moke = {
    description: "بيت  تجاوز - للبيع -  المساحة 300 م2",
    id: "83b976c5-ef06-4831-8dc9-9fb260bae928",
    imageUrl: "https://dallalstorage.blob.core.windows.net/dallalstorage/whatsapp_image_2019_06_19_at_42331_pm1.jpeg",
    price: 50000000,
    title: "Test",
};
// let localeProdects;
const Products = () => {
    const [products, setProducts] = useState([moke]);
    React.useEffect(() => {
        let data = JSON.parse(localStorage.getItem("productsApi"));
        console.log(data);
        return;
        const Done = ({ data }) => {
            setProducts(data);
            localStorage.setItem("productsApi", JSON.stringify(data));
            console.log(data);
        };
        const Error = (err) => {
            // console.error(err);
        };
        let filter = [
            //
            { offset: 0 },
            { limite: 25 },
        ];

        ProductsController.GetProducts(Done, Error, filter);
    }, []);
    const updateStorgeProductInfo = (product) => {
        // localeProdects = localeProdects.filter((o) => o.id !== product.id);
        // if (product.isPined) localeProdects.push(product);
        // localStorage.setItem("products", JSON.stringify(localeProdects));
        // setUpdateUi(!updateUi);
    };
    return (
        <Scroller className="larg-grid">
            <Link to="/object-creator" className="col-span-full" style={{ textDecorationSkip: "none" }}>
                <p className="cyan-card font-bold p-3 w-36 mb-4 white-text shadow-lg rounded-full text-center"> اضافة مدن</p>
            </Link>
            <Link to="/products/add" className="col-span-full" style={{ textDecorationSkip: "none" }}>
                <IconButton />
            </Link>
            {products.map((product, i) => (
                <ProductsCard key={i} product={product} updateStorge={updateStorgeProductInfo} />
            ))}
        </Scroller>
    );
};

export default Products;
