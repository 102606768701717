import React from "react";

const NextButton = ({ onClick, top = "50%", right = 45 }) => (
    <button className="embla-Button embla-Right" style={{ right, top }} onClick={onClick}>
        <svg className="embla-ButtonSvg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 142 229.06">
            <path
                d="M429,501v-2c1.34-1.22,2.74-2.39,4-3.67q53-52.93,105.87-105.89c1.24-1.24,2.57-2.38,4.46-4.11,2.59,2.87,4.8,5.59,7.28,8C557.36,400,564.2,406.46,571,413v1l-86.24,86.31L571,586v1c-2.56,2.3-5.22,4.51-7.67,6.93-6.83,6.77-13.56,13.62-20.36,20.46-19.88-19.82-39.3-39.21-58.76-58.56Q456.64,528.38,429,501Z"
                transform="translate(-429 -385.33)"
            />
        </svg>
    </button>
);

export default NextButton;
