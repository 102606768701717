import React from "react";
import Loader from "../Components/Loader";
import ListLoader from "./ListLoader";

const Scroller = ({ fetchData, canLoadMore, loading, className = "", children }) => {
    const testRef = React.useRef(null);

    const itemsScroll = ({ target }) => {
        if (canLoadMore && target.scrollHeight - target.scrollTop < target.clientHeight + 250) {
            canLoadMore = false;
            fetchData(false);
        }
    };

    return (
        <div ref={testRef} onScroll={itemsScroll} className={`scrller-container scroller ${className}`}>
            {loading ? <Loader /> : <div className="z-50 ">{children}</div>}
            {canLoadMore && <ListLoader />}
            <p style={{ height: 200 }} />
        </div>
    );
};

export default Scroller;
