import React from "react";
import Languages from "../Utils/Languages";

const TextInput = ({ value, setValue, Key, required, autoFocus, inputRef, type = "text" }) => (
    <input
        ref={inputRef}
        name={Key}
        type={type}
        value={value}
        required={required}
        autoFocus={autoFocus}
        placeholder={Languages[Key] || Key}
        onChange={({ target }) => setValue(target.value)}
        className="font-bold flex-grow outline-none carbonic carbonic-card border-none px-4 py-2  rounded-xl shadow-lg focus:shadow-cyan"
    />
);

export default TextInput;
// <TextInput value={} setValue={}  Key="" />
