import React, { useState } from "react";
import Button from "../Components/Button";
import Languages from "../Utils/Languages";
import AreaCard from "./AreaCard";
import AreaLabels from "./AreaLabels";

const Area = ({ prop, onDone }) => {
    let [otherData, setOtherData] = useState([]);
    const [poupClass, setPopupClass] = useState("");

    const removeType = (i) => {
        otherData = otherData.filter((_, _i) => _i !== i);
        otherData.forEach((item, i) => {
            item.title = Languages[item.type];
        });
        setOtherData(otherData);
    };
    const haveData = otherData.length > 0;

    return (
        <div className={`orange-card-2- col ${poupClass}`}>
            {haveData ? (
                <AreaLabels otherData={otherData} removeType={removeType} extraClass="" />
            ) : poupClass ? (
                <AreaCard setPopupClass={setPopupClass} prop={prop} setOtherData={setOtherData} otherData={otherData} TypeTitle="مساحة المنزل" />
            ) : (
                <div className="row-center popup-card">
                    <p className="font-bold flex carbonic ml-auto mr-2">{prop.title}</p>
                    <Button onClick={() => setPopupClass("popup-2 blur-card-1")} title={`+ اضافة ابعاد المنزل `} color="redText" extraClass="px-4" />
                </div>
            )}
        </div>
    );
};
export default Area;
