import React from "react";

const ProgresBar = ({ progres }) => {
    return (
        <div className="flex-grow mx-auto left-5 right-5 max-w-xl mt-1 p-2 shadow-lg carbonic-card-1 rounded-full ">
            <p style={{ width: progres }} className={`h-2 transtion rounded-full cyan-card shadow-cyan`}></p>
        </div>
    );
};

export default ProgresBar;
