import React from "react";
import Button from "../Components/Button";
import SmallCarousel from "../Containers/SmallCarousel";

const ImagesBrwoser = ({ images, setImages }) => {
    const inputFileRef = React.createRef();
    const filesChanged = (e) => {
        e.preventDefault();
        let files = e.target.files;
        let blob = [];
        let _files = [];
        // let formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            let file = files[i];
            blob.push(URL.createObjectURL(file));
            _files.push(file);
            // let { name, data } = file;
            // let type = `image/${name.substring(name.length - 3)}`;
            // formData.append("photos", { name, data, type });
        }

        setImages({ blob, files: _files });
    };
    return (
        <>
            <Button title="AddImages" extraClass="my-2" onClick={() => inputFileRef.current.click()} />
            <SmallCarousel Images={images.blob} />
            <input
                //
                type="file"
                accept="image/*"
                className="hidden"
                multiple={true}
                ref={inputFileRef}
                onChange={filesChanged}
            />
        </>
    );
};

export default ImagesBrwoser;
