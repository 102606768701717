import Languages from "../../Utils/Languages";

const HouseKeys = [
    "Title",
    "Contact", //
    "AddressId",
    "Price",
    "Currency",
    "OfferType", //
    "Property",
    "InternetServiceQuality",
    "ElectricalServiceQuality",
    "WaterServiceQuality",
    "SewerServiceQuality",
    "Foundation",
    "IsItStructure",
    "Material",
    "DoseHaveGarden",
    "DoesItContainCeramic",
    "IsTheFlooCeramic",
    "NeighborsQuality",
    "DistanceToSchool",
    "DistanceToMarket",
    "DistanceToTheHealthCenter",
    //20
    "Area",
    "FloorCount",
    "WCsCount",
    "Age",
    "InterfaceLength",
    "SideLength",
    "StreetWidth", //27
    "Room",
    "Wc",
    "LivingRoom",
    "GuestRoom",
    "Kitchen",
    "",
];
const Quality = ["Bad", "Normal", "Middle", "High", "VeryHigh"];
const Distance = ["Near", "Middle", "Far"];
const Boolean = ["Yes", "No"];
export const ProductsListsData = {
    Material: ["Bricks", "Blocks"],
    Property: ["Transcend", "AgriculturalOwnership", "GovernmentOwnership"],
    Foundation: ["Brikes", "Concrete"],
    OfferType: ["Sale", "Rent", "Mortgage"],
    NeighborsQuality: Quality,
    WaterServiceQuality: Quality,
    ElectricalServiceQuality: Quality,
    SewerServiceQuality: Quality,
    InternetServiceQuality: Quality,

    DistanceToSchool: Distance,
    DistanceToMarket: Distance,
    DistanceToTheHealthCenter: Distance,

    DoseHaveGarden: Boolean,
    DoesItContainCeramic: Boolean,
    IsTheFlooCeramic: Boolean,
    IsItStructure: Boolean,

    Currency: ["USD", "IQD"],
};
export const ListToUi = {
    Quality: [Languages["Bad"], Languages["Normal"], Languages["Middle"], Languages["High"], Languages["VeryHigh"]],
    OfferType: [Languages["Sale"], Languages["Rent"], Languages["Mortgage"]],
    Foundation: [Languages["Brikes"], Languages["Concrete"]],
    Property: [Languages["Transcend"], Languages["AgriculturalOwnership"], Languages["GovernmentOwnership"]],
    Material: [Languages["Bricks"], Languages["Blocks"]],
    Distance: [Languages["Near"], Languages["Middle"], Languages["Far"]],
};

export default HouseKeys;
