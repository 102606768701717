import Languages from "../../Utils/Languages";
import { ListToUi } from "../Keys/HouseKeys";
// "Title",
// "Contact", //
// "AddressId",
// "Price",
// "Currency",
const CutomProps = [
    "OfferType", //
    "Property",
    "Foundation",
    "Material",
];
const OtherProps = [
    //
    "FloorCount",
    "Age",
    //Area
    "InterfaceLength",
    "SideLength",
    "StreetWidth",
];
// const HouseDetials = [
//     //
//     "Room",
//     "Wc",
//     "LivingRoom",
//     "GuestRoom",
//     "Kitchen",
// ];

const Boolean = [
    //
    "IsItStructure",
    "DoseHaveGarden",
    "DoesItContainCeramic",
    "IsTheFlooCeramic",
];
const Distance = [
    //
    "DistanceToSchool",
    "DistanceToMarket",
    "DistanceToTheHealthCenter",
];

const Quality = [
    //
    "InternetServiceQuality",
    "ElectricalServiceQuality",
    "WaterServiceQuality",
    "SewerServiceQuality",
    "NeighborsQuality",
];

const DataToUiFormater = {
    FormateHouse: (house) => {
        let houseToUi = {
            booleans: GetBooleans(house),
            qualities: GetQuality(house),
            distances: GetDistance(house),
            cutomProps: GetCutomProps(house),
            otherProps: GetOtherProps(house),
            houseDetials: GetHouseDetials(house),
        };
        // Object.keys(house).forEach((key) => {
        //     // house[key] =
        //     let pascalCaseKey = key.charAt(0).toUpperCase() + key.slice(1);
        //     let list = ProductsListsData[pascalCaseKey];
        //     if (list) {
        //         let value = list[house[key]];
        //         // let fullString = Languages[pascalCaseKey] + " " + Languages[value];
        //         let fullString = { key: Languages[pascalCaseKey], value: Languages[value] };
        //         props.push(fullString);
        //         // console.log(Languages[value]);
        //         // console.log(house[key]);
        //         // console.log({ houseKey: house[key], value });
        //         //[house[key]];
        //     }
        // });
        house.props = [
            //
            ...houseToUi.cutomProps,
            ...houseToUi.otherProps,
            ...houseToUi.booleans,
            ...houseToUi.qualities,
            ...houseToUi.distances,
            ...houseToUi.houseDetials,
        ];
    },
};
const GetBooleans = (house) => {
    let booleans = [];
    Boolean.forEach((key) => {
        let camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
        if (house[camelCaseKey]) {
            let fullString = { key: "البيت", value: Languages[key] };
            booleans.push(fullString);
        }
    });
    return booleans;
};
const GetQuality = (house) => {
    let qualities = [];
    Quality.forEach((key) => {
        let camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
        let value = house[camelCaseKey];
        house[camelCaseKey]++;
        // if (house[camelCaseKey]) {
        // let fullString = Languages[key] + " " + ListToUi.Quality[value];
        let fullString = { key: Languages.Qualities[key], value: ListToUi.Quality[value] };
        qualities.push(fullString);
        // }
    });
    return qualities;
};

const GetDistance = (house) => {
    let distances = [];
    Distance.forEach((key) => {
        let camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
        let houseValue = house[camelCaseKey];
        // if (houseValue) {
        // let fullString = Languages[key] + " " + ListToUi.Distance[houseValue];
        let fullString = { key: "المسافة الى ", value: Languages.Distances[key] + " " + ListToUi.Distance[houseValue] };
        distances.push(fullString);
        // }
    });
    return distances;
};
const GetOtherProps = (house) => {
    let otherProps = [];
    OtherProps.forEach((key) => {
        let camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
        let houseValue = house[camelCaseKey];
        if (houseValue) {
            // let fullString = Languages[key] + " " + houseValue;
            let fullString = { key: Languages[key], value: houseValue };
            otherProps.push(fullString);
        }
    });
    return otherProps;
};
const GetCutomProps = (house) => {
    let cutomProps = [];
    CutomProps.forEach((key) => {
        let camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
        let houseValue = house[camelCaseKey];
        if (houseValue) {
            // let fullString = Languages[key] + " " + ListToUi[key][houseValue];
            let fullString = { key: Languages[key], value: ListToUi[key][houseValue] };
            cutomProps.push(fullString);
        }
    });
    return cutomProps;
};
const GetHouseDetials = (house) => {
    let houseDetials = [];
    house.houseDetails.forEach((detail) => {
        let fullString = { key: detail.title, value: `${detail.length}م  -  ${detail.width}م` };
        houseDetials.push(fullString);
    });

    return houseDetials;
};

export default DataToUiFormater;
