import axios from "axios";

const ApiRoot = "https://af09-37-236-139-29.eu.ngrok.io/api/v1";
const ImageApiRoot = "https://imgbb.com/json";
const getAxiosInstance = (root) => {
    let instance = axios.create({
        baseURL: root,
        headers: {
            "Content-Type": "application/json",
        },
    });
    // instance.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
    return instance;
};

export const API = getAxiosInstance(ApiRoot);
export const ImageApi = getAxiosInstance(ImageApiRoot);

let auth_token = "1430c38f109946cd8c283dce1375555cc40ae818";
const Uploader = {
    uploadImage: async function (file, onComplate) {
        let formData = getFormData(file);
        return await ImageApi.post("", formData);
    },
};

const getFormData = (file) => {
    let formData = new FormData();
    formData.append("source", file);
    formData.append("type", "file");
    formData.append("action", "upload");
    formData.append("timestamp", Date.now());
    formData.append("auth_token", auth_token);
    return formData;
};

export default Uploader;
