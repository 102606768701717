import React from "react";
import MultipleArea from "./MultipleArea";
import Options from "./Options";
import Area from "./Area";
import String from "./String";
import Number from "./Number";
import InputOptions from "./InputOptions";

const MultyPropsPopup = ({ prop, onDone }) => {
    const Body = PopupTypes[prop.type];
    const _Container = `${prop.class} px-4`;
    return (
        <div className={_Container}>
            <Body prop={prop} onDone={onDone} />
        </div>
    );
};

export default MultyPropsPopup;

const PopupTypes = {
    Options,
    String,
    InputOptions,
    Number,
    Area,
    MultipleArea,
};
