import React from "react";
import useLocalStorage from "@rehooks/local-storage";
import PopupContainer from "../Components/PopupContainer";
import Form from "../Containers/Form";
import MultyPropsPopup from "./MultyPropsPopup";
import Button from "../Components/Button";
import Bird from "../Bird/Bird";

const animationsTime = 200;

const PopupList = ({ children, changeGroup }) => {
    const [popup, setPopup] = useLocalStorage("popup");
    const [animationClass, setAnimationClass] = React.useState("fade-in");

    const [, refresh] = React.useState("");

    const close = () => {
        setPopup(null);
    };

    const onDone = () => {
        let requiredProp = popup.props.filter((o) => o.required && !o.value)[0];
        if (!requiredProp) onSubmint(setAnimationClass, popup, changeGroup);
        else {
            requiredProp.class = "shake";
            refresh(Math.random());
            setTimeout(() => {
                requiredProp.class = "";
                refresh(Math.random());
            }, 1000);
            Bird(` ${requiredProp.title} مطلوب`);
        }
    };
    return popup ? (
        <PopupContainer close={close}>
            <Form onSubmit={onDone} cancle={onDone} canSubmit={true} cancleText="Pass" className={`${animationClass}`} progres={popup.progres}>
                <div className="row-center justify-between absolute top-1 left-10 right-2">
                    <Button color="red" title="<-" extraClass="px-3 font-light rounded-full py-1" onClick={() => changeGroup(popup.groupId - 1)} />
                    <p className="carbonic font-bold text-xl px-5 mr-6">{popup.title}</p>
                    <Button color="red" title="x" extraClass="px-4 font-light py-1.5" onClick={() => setPopup(null)} />
                </div>
                <div className="my-auto col gap-3 py-3 overflow-x-hidden">
                    {popup.props?.map((prop, i) => (
                        <MultyPropsPopup key={i} prop={prop} onDone={refresh} />
                    ))}
                </div>
            </Form>
        </PopupContainer>
    ) : (
        <>{children}</>
    );
};

export default PopupList;

const onSubmint = (setAnimationClass, popup, changeGroup) => {
    setAnimationClass("fade-out");
    console.log({ props: popup });

    let infos = JSON.parse(localStorage.getItem("colctedHouseInfo")) || {};

    if (popup.parntKey) injectPropsToParent(popup, infos);
    else injectProps(popup, infos);

    console.log({ infos });
    localStorage.setItem("colctedHouseInfo", JSON.stringify(infos));

    setTimeout(() => {
        // popup.onDone && popup.onDone();
        changeGroup(popup.groupId + 1);
        setAnimationClass("fade-in");
    }, animationsTime);
};

function injectProps(popup, infos) {
    popup.props?.forEach((info) => {
        infos[info.key] = info.value;
        info.optionsKey && (infos[info.optionsKey] = info.optionValue);
    });
}

function injectPropsToParent({ parntKey, props }, infos) {
    if (!infos[parntKey]) infos[parntKey] = [];

    console.log({ infos });
    props?.forEach((info) => {
        info.values?.forEach((childValue) => {
            console.log({ parntKey: infos[parntKey] }, { childValue });
            console.log(childValue);
            if (infos[parntKey].indexOf((o) => o.id === childValue.id) === -1) infos[parntKey].push(childValue);
        });
    });
}
