import { writeStorage } from "@rehooks/local-storage";
import Languages from "../../Utils/Languages";
import HouseProps from "../House/HouseProps";

const CheckInjections = (prop, PropsOptions) => {
    let options = PropsOptions[prop.type];
    if (options) {
        prop.type = prop.optionsKey ? "InputOptions" : "Options";
        InjectOptions(prop, options);
    }
};

const UiToDataFormater = {
    SetupPopup: (groupId) => {
        let { Groups, PropsOptions, PropsNames, GroupsTitles } = HouseProps;
        let group = Groups[groupId];
        let popupUi = {
            props: [],
            title: GroupsTitles[group.title],
            progres: Math.round((group.progres / 30) * 100) + "%" || "0%",
            parntKey: group.parntKey,
            groupId,
        };
        group.props.forEach((prop) => {
            CheckInjections(prop, PropsOptions);
            Standerd(prop, PropsNames);
            popupUi.props.push(prop);
        });
        // console.log(popupUi);
        writeStorage("popup", popupUi);
    },
};

const InjectOptions = (propData, options) => {
    propData.items = [];
    options.forEach((item, i) => {
        propData.items.push({ key: item, title: Languages[item], value: i + 1 });
    });
};

const Standerd = (prop, PropsNames) => {
    const { key } = prop;
    let infos = JSON.parse(localStorage.getItem("colctedHouseInfo")) || { [key]: "" };
    prop.title = PropsNames[key];
    prop.value = infos[key];
};

export default UiToDataFormater;
// function newFunction(prop, PropsOptions, PropsNames, options) {
//     const { key, type } = prop;
//     console.log(type);
//     let infos = JSON.parse(localStorage.getItem("colctedHouseInfo")) || { [key]: "" };

//     let propData = { key, type, title: PropsNames[key], value: infos[key] };

//     propData.items = [];
//     options.forEach((item, i) => {
//         propData.items.push({ key: item, title: Languages[item], value: i + 1 });
//     });
//     propData.items.push({ key: "None", title: Languages["None"], value: 0 });

//     // propData.autoFocus = i === 0;
//     propData.required = prop.required;
//     return propData;
// }
