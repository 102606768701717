import React from "react";
import useLocalStorage from "@rehooks/local-storage";
import { BrowserRouter as Router } from "react-router-dom";
import AuthRoutes from "./Routes/AuthRoutes";
import BirdContainer from "./Bird/BirdContainer";

function App() {
    const [theme, setTheme] = useLocalStorage("theme");

    const changeTheme = () => {
        setTheme(theme !== "dark" ? "dark" : "light");
        document.documentElement.style.setProperty("--main-bg-color", theme !== "dark" ? "#22212e" : "#f5f5f5");
    };

    React.useEffect(() => {
        document.documentElement.style.setProperty("--main-bg-color", (theme || "dark") === "dark" ? "#22212e" : "#f5f5f5");
    }, [theme]);

    return (
        <Router>
            <div className={`mx-auto max-w-5xl px-2 ${theme || "dark"}`}>
                <AuthRoutes />
            </div>
            <BirdContainer />
            <p style={{ left: 0, top: 0 }} className="font-light z-50 text-2xl fixed" onClick={changeTheme}>
                {theme === "light" ? "🌞" : "🌙"}
            </p>
        </Router>
    );
}

export default App;
