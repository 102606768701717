import React from "react";
import Languages from "../Utils/Languages";

// const Line = () => <span className="flex-grow orange-card pt-0.5" />;

const AreaLabels = ({ otherData, removeType, extraClass = "pb-32" }) => {
    return (
        <div className={`col scroller gap-2 p-2 overflow-y-auto max-h-[80vh] ${extraClass}`}>
            {otherData.map(({ width, length, title }, i) => (
                <div key={i} className="row-center font-bold">
                    {/* <p className="font-bold carbonic-2 text-sm px-3 py-1.5 cyan-card carbonic-card shadow-cyan rounded-xl">{title}</p> */}
                    <p className="carbonic-2 row carbonic-card flex-grow items-center py-0.5 px-4 rounded-xl min-w-max shadow-cyan ">
                        <span className="text-xs min-w-[110px] ml-2">{title}: </span>
                        <span className="text-xs pl-2">{Languages.Width}: </span>
                        <span className="orange-text text-xl pb-1">{width}</span>
                        <span className="px-2 font-light mx-2"> x </span>
                        <span className="text-xs pl-2">{Languages.Length}: </span>
                        <span className="orange-text text-xl pb-1">{length}</span>
                        <span onClick={() => removeType(i)} className="white-text text-sm font-bold rounded-md px-1 mr-auto orange-card shadow-lg">
                            حذف
                        </span>
                    </p>
                </div>
            ))}
        </div>
    );
};

export default AreaLabels;
