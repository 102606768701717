export const Pin = ({ onClick, className }) => (
    <svg
        onClick={onClick}
        className={`h-8 cursor-pointer hover:-translate-y-1 transition-all  mr-auto ${className}`}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 319.808 319.808">
        <g>
            <path
                d="M317.16,159.471c-16.197-16.199-41.195-24.41-74.307-24.41c-7.01,0-14.408,0.379-22.047,1.121l-79.729-75.346l19.04-19.035
                c3.529-3.531,3.529-9.252-0.003-12.785l-26.42-26.424c-3.39-3.391-9.394-3.391-12.784,0L2.648,120.863
                c-3.531,3.531-3.531,9.252-0.002,12.783l26.416,26.416c1.697,1.695,3.995,2.648,6.394,2.648c2.397,0,4.697-0.953,6.392-2.648
                l19.042-19.035l75.346,79.726c-3.048,31.352-1.057,72.018,23.284,96.358c1.766,1.766,4.079,2.648,6.391,2.648
                c2.314,0,4.629-0.884,6.395-2.648l66.035-66.041l50.578,50.582c1.768,1.766,4.08,2.648,6.395,2.648
                c2.312,0,4.629-0.884,6.393-2.648c3.531-3.531,3.531-9.254,0-12.785l-50.582-50.58l66.037-66.03
                C320.691,168.725,320.691,163.005,317.16,159.471z M166.505,297.334c-14.614-21.172-14.7-53.803-11.859-78.743
                c0.3-2.648-0.581-5.297-2.413-7.23l-84.589-89.516c-1.678-1.775-4.002-2.791-6.443-2.826c-0.042,0-0.086,0-0.128,0
                c-2.397,0-4.697,0.953-6.392,2.647l-19.225,19.221l-13.632-13.631L127.303,21.769l13.636,13.641L121.716,54.63
                c-1.726,1.73-2.68,4.078-2.646,6.525c0.035,2.436,1.057,4.768,2.832,6.443l89.507,84.582c1.936,1.836,4.574,2.719,7.23,2.41
                c8.455-0.963,16.602-1.449,24.213-1.449c23.465,0,41.756,4.469,54.539,13.314L166.505,297.334z"
            />
        </g>
    </svg>
);
export const Select = (
    <svg height={16} className="select-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264.55 377.7">
        <path
            d="M501,311c7,1.28,12.13,5.47,17,10.42q53.38,53.68,107,107.1c6.07,6.05,8.78,12.87,6.06,21.23a18.8,18.8,0,0,1-28.52,9.66,43.23,43.23,0,0,1-6-5.27q-46.15-46.08-92.23-92.23c-1.29-1.29-2.34-2.82-3.85-4.67-1.85,1.74-3.17,2.91-4.41,4.15q-47,47-94,94c-5.1,5.11-10.84,8.32-18.26,7.12a18.86,18.86,0,0,1-11.66-31c3.74-4.45,8.09-8.39,12.21-12.51,33.45-33.46,66.81-67,100.5-100.21,3.68-3.61,9.3-5.25,14-7.81Z"
            transform="translate(-367.68 -311)"
        />
        <path
            d="M499.36,643.31c9.21-9.26,17.17-17.31,25.18-25.32q37.1-37.14,74.23-74.21c11.36-11.3,29-7,32.8,7.89,2,7.65-.64,14-6.13,19.45Q603.65,592.8,582,614.59q-33,33-66.09,66.08c-10.72,10.7-21,10.71-31.68,0q-54.62-54.59-109.25-109.18c-6.05-6-8.86-12.85-6.19-21.21a18.78,18.78,0,0,1,28.43-9.93,41.48,41.48,0,0,1,6,5.28q46.32,46.27,92.58,92.62C497,639.55,497.89,641.24,499.36,643.31Z"
            transform="translate(-367.68 -311)"
        />
    </svg>
);
// const Svg = {};
// export default Svg;
