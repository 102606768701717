import React from "react";
import Languages from "../Utils/Languages";
import ListLabel from "./ListLabel";

const InputOptions = ({ prop, onDone }) => {
    const [inputValue, setInputValue] = React.useState(prop.value || "");
    const { title, required } = prop;

    const onSelect = (item) => {
        const unSelect = prop.optionValue === item.value;
        prop.optionValue = unSelect ? 0 : item.value;
        onDone(Math.random());
    };

    return (
        <div className="popup-card col">
            <input
                name={title}
                type="number"
                value={inputValue}
                required={required}
                // autoFocus={autoFocus}
                placeholder={Languages[title] || title}
                onChange={({ target }) => {
                    let text = target.value;
                    prop.value = text;
                    setInputValue(text);
                }}
                className="font-bold flex-grow outline-none carbonic carbonic-card border-none px-4 py-2 mx-2 rounded-xl shadow-lg"
            />
            <div className="scroller horizontal-scroller">
                {prop.items?.map((item) => (
                    <ListLabel key={item.key} title={item.title} active={prop.optionValue === item.value} onClick={() => onSelect(item)} />
                ))}
            </div>
        </div>
    );
};

export default InputOptions;
