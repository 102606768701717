import React from "react";
const ListLabel = ({ title, active, onClick }) => (
    <p
        onClick={onClick}
        className={`${
            active ? "active-list-label shadow-cyan" : "carbonic-card-2 shadow-md carbonic-2"
        } font-bold cursor-pointer text-sm rounded-lg list-label tap-highlight`}>
        {title}
    </p>
);

export default ListLabel;
