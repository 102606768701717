import React from "react";
import Button from "../Components/Button";
import PopupList from "../Popup/PopupList";
import Scroller from "../Containers/Scroller";
import { writeStorage, useLocalStorage } from "@rehooks/local-storage";
import ImagesBrwoser from "../FilesComponents/ImagesBrwoser";
import UiToDataFormater from "./Api/UiToDataFormater";
// import Uploader, { API } from "../Api/API";

const AddProduct = () => {
    let [_ProductData] = useLocalStorage("ProductData");
    const [images, setImages] = React.useState({ blob: [], files: "" });
    const ChangeGroup = (groupId = 0) => {
        if (groupId < 7) {
            UiToDataFormater.SetupPopup(groupId);
        }
        console.log(JSON.parse(localStorage.getItem("colctedHouseInfo")));
    };
    // localStorage.clear();
    const uploadImages = () => {
        // if (images.files.length < 1) {
        //     postProduct([]);
        // } else {
        //     let promeses = [];
        //     images.files.forEach((file) => {
        //         promeses.push(Uploader.uploadImage(file));
        //     });
        //     // promeses[0]().then((res) => {
        //     //     console.log(res);
        //     // });
        //     let imageUrls = [];
        //     Promise.all(promeses)
        //         .then((res) => {
        //             console.log(res);
        //             res.forEach((res) => {
        //                 console.log(res.data.image.display_url);
        //                 imageUrls.push(res.data.image.display_url);
        //             });
        //             postProduct(imageUrls);
        //         })
        //         .catch((err) => {
        //             alert("Error");
        //         });
        // }
    };

    // const postProduct = (imageUrls) => {
    //     imageUrls = [
    //         "https://pi.movoto.com/p/694/60216632_0_vU3juZ_p.jpeg",
    //         "https://pi.movoto.com/p/861/1609427_0_j2UbnN_p.jpeg",
    //         "https://photos.zillowstatic.com/fp/0fca4188d563e60251e69d5856748506-p_e.jpg",
    //         "https://photos.zillowstatic.com/fp/fbe64887807a7a6129b0f319b9d5c253-p_e.jpg",
    //     ];
    //     _ProductData.Title = { key: "Title", value: Title };
    //     _ProductData.AddressId = { key: "AddressId", value: "3fa85f64-5717-4562-b3fc-2c963f66afa6" };
    //     _ProductData.Price = { key: "Price", value: Price };
    //     let dataToSend = SetupPopupToPost(_ProductData);
    //     // console.log(dataToSend);
    //     dataToSend.imageUrls = imageUrls;
    //     localStorage.setItem("NiceHouse_2", JSON.stringify(dataToSend));
    //     // let dataToSend = JSON.parse(localStorage.getItem("DATATOSEND_3"));
    //     API.post("/houses", dataToSend)
    //         .then((res) => {
    //             console.log(res);
    //             alert("done");
    //         })
    //         .catch((err) => {
    //             // alert("Error");
    //         });
    // };

    return (
        <Scroller className="max-w-3xl col mx-auto">
            <PopupList changeGroup={ChangeGroup}>
                <div className="col h-[80vh] my-auto max-w-xs mx-auto justify-center">
                    <Button
                        title="Clear"
                        onClick={() => {
                            localStorage.clear();
                            writeStorage("ProductData", null);
                        }}
                    />
                    <Button onClick={uploadImages} title="Send" color="cyan" extraClass="my-10" />
                    <Button title="Next" color="red" extraClass="mb-10" onClick={() => ChangeGroup(0)} />
                    <ImagesBrwoser images={images} setImages={setImages} />
                </div>
            </PopupList>
        </Scroller>
    );
};

export default AddProduct;
