import Languages from "./Languages";

const StringFormater = {
    NumberAsString: (key, number) => {
        let converted = Languages[key] + " " + NumberToString[number];
        // let converted = " " + NumberToString[number];
        if (["room", "livingRoom"].includes(key)) converted += number === 0 ? "ى" : "ة";
        return converted;
    },
};
const NumberToString = {
    0: "الاول",
    1: "الثاني",
    2: "الثالث",
    3: "الرابع",
    4: "الخامس",
    5: "السادس",
    6: "السابع",
    7: "الثامن",
    8: "التاسع",
    9: "العاشر",
    10: "الحادي عشر",
    11: "الثاني عشر",
    12: "الثالث عشر",
    13: "الرابع عشر",
    14: "الخامس عشر",
    15: "السادس عشر",
    16: "السابع عشر",
    17: "الثامن عشر",
    18: "التاسع عشر",
};

export default StringFormater;
