import React from "react";
import Languages from "../Utils/Languages";

const NumberInput = ({ value, setValue, Key, required, autoFocus, inputRef, type = "text", className }) => (
    <input
        ref={inputRef}
        name={Key}
        type={type}
        value={value}
        required={required}
        autoFocus={autoFocus}
        placeholder={Languages[Key] || Key}
        onChange={({ target }) => setValue(target.value)}
        className={`font-bold outline-none carbonic border-none shadow-sm number-input px-2 py-1 ${className}`}
    />
);

export default NumberInput;
// <TextInput value={} setValue={}  Key="" />
